<template>
  <div class="dd-banner">
    <h3>企业专属钉钉</h3>
    <p>助力企业打造专属、安全、开放的<br/>数字化办公运营平台，<br/>定义企业自己的数字化工作学习方式</p>
    <a :href="onLine" target="_blank">立即咨询</a>
  </div>
  <Intro/>
  <DingTalk/>
  <Advantage/>
  <Cases/>
</template>
<script>
import { onLine } from "@/data.ts";
import Intro from '../../components/dingtalk/Intro.vue';
import DingTalk from '../../components/dingtalk/DingTalk.vue';
import Advantage from '../../components/dingtalk/Advantage.vue';
import Cases from '../../components/dingtalk/Cases.vue';
export default {
  components:{
    Intro,
    DingTalk,
    Advantage,
    Cases
  },
  data() {
    return { onLine }
  }
}
</script>
<style lang="less" scoped>
.dd-banner{
  padding: 65px 25px;
  background: url(/static/img/dd-banner.jpg) no-repeat center;
  background-size: cover;
  color: #fff;
  >h3{
    font-size: 20px;
    font-weight: bold;
  }
  >p{
    font-size: 15px;
    font-weight: 300;
    line-height: 1.8;
    margin-top: 10px;
  }
  >a{
    text-align: center;
    display: inline-block;
    height: 40px;
    line-height: 40px;
    background: #F23D49;
    border-radius: 3px;
    font-size: 16px;
    font-weight: bold;
    color: #FFFFFF;
    margin-top: 30px;
    border: 0 none;
    width: 115px;
  }
}
</style>
